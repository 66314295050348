import React from "react"
import { PageProps, Link } from "gatsby"
import { Row, Col } from "react-bootstrap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faLightbulb,
  faCheck,
  faWeightHanging,
  faThumbsUp,
} from "@fortawesome/free-solid-svg-icons"

import Layout from "../../components/PageLayout"
import SEO from "../../components/seo"
import Hero from "../../components/Hero"
import ContactForm from "../../components/ContactForm"
import SalesPoint from "../../components/SalesPoint"

const CloudConsultancy = (props: PageProps) => (
  <Layout
    title={"Rapid Prototyping"}
    hero={
      <Hero
        title="Rapid Prototyping"
        subtitle="See your ideas come to life in as little as two weeks."
        icon={
          <FontAwesomeIcon
            icon={faLightbulb}
            color={"var(--white)"}
            size={"4x"}
          />
        }
      />
    }
  >
    <SEO title="Rapid Prototyping" />
    <Row>
      <Col sm={12} md={12} lg={8}>
        <SalesPoint icon={faCheck} title={"Move fast with confidence"}>
          Often it's important to prove out ideas and concepts quickly. The
          trouble is traditional agencies will overcomplicate the process and
          inflate costs with lots of discovery. We are experts in creating
          prototype mobile apps and cloud applications with minimal direction,
          using a unique rapid feedback process to deliver value with minimal
          overhead.
        </SalesPoint>

        <SalesPoint icon={faWeightHanging} title="Stay lean">
          There's no point adding all the bells and whistles (and cost!) when
          really all you need is a prototype. We can work with you to cut
          through the noise and figure out what you actually need to test,
          quickly.
        </SalesPoint>

        <SalesPoint
          icon={faThumbsUp}
          title="Mobile app? Cloud system? Hardware? No worries."
        >
          We have experience producing mobile applications, cloud architectures,
          microservices, serverless functions, hardware and more to a tight
          deadline and budget. We can do it, and we can do it well.
        </SalesPoint>
      </Col>
      <Col>
        <ContactForm lead="We want to help prototype your idea. Fill in this form and one of the team will contact you regarding your project." />
      </Col>
    </Row>
  </Layout>
)

export default CloudConsultancy
