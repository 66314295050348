import React from "react"
import {
  FontAwesomeIcon,
  FontAwesomeIconProps,
} from "@fortawesome/react-fontawesome"
import icons from "@fortawesome/free-solid-svg-icons"

import Icon from "../Icon"

interface SalesPointProps {
  icon: FontAwesomeIconProps["icon"]
  title: string
  children: string
}

const SalesPoint = (props: SalesPointProps) => (
  <div className="d-flex align-items-start mb-4">
    <Icon icon={props.icon} />
    <div>
      <h3>{props.title}</h3>
      <p>{props.children}</p>
    </div>
  </div>
)

export default SalesPoint
