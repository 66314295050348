import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  SalesPointIconWrapper,
  SalesPointIcon,
  SalesPointImage,
} from "./style.module.scss"

export default props => (
  <div className={`mr-4 ${SalesPointIconWrapper}`}>
    {props.image ? (
      <img src={props.image} className={SalesPointImage} />
    ) : (
      <FontAwesomeIcon icon={props.icon} size="3x" className={SalesPointIcon} />
    )}
  </div>
)
