/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { Container } from "react-bootstrap"

import Header from "../Header"
import RootLayout from "../RootLayout"

const PageLayout = ({ title, children, hero = null }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  let HeroComponent
  if (hero) {
    HeroComponent = hero
  }

  return (
    <RootLayout>
      <Header
        siteTitle={`${title} - ${data.site.siteMetadata.title}`}
        logoColor="color"
      />
      {HeroComponent}
      <Container className="mt-4">
        {!HeroComponent && <h1 className="h2">{title}</h1>}
        <main>{children}</main>
        <footer
          className="mt-4 pb-4 pt-4"
          style={{ borderTop: "1px solid var(--light)" }}
        >
          © {new Date().getFullYear()} Freshnode NZ Limited, All rights reserved
        </footer>
      </Container>
    </RootLayout>
  )
}

PageLayout.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default PageLayout
