import React from "react"
import { Jumbotron, Container, Image } from "react-bootstrap"

interface HeroProps {
  title: string
  subtitle?: string
  backgroundImage?: string
  icon?: string | JSX.Element
}

import { Title, Subtitle } from "./style.module.scss"

function Hero(props: HeroProps) {
  let icon
  if (props.icon) {
    if (typeof props.icon === "object") {
      icon = (
        <div
          className="mr-4 d-none d-md-flex align-items-center justify-content-center"
          style={{ minWidth: 76 }}
        >
          {props.icon}
        </div>
      )
    } else {
      icon = (
        <img
          className="mr-4 d-none d-md-block"
          src={props.icon as string}
          width={98}
          height={98}
        />
      )
    }
  }

  return (
    <Jumbotron
      fluid
      className="position-relative mb-5"
      style={{
        overflow: "hidden",
        background:
          "linear-gradient(180deg, rgba(166,64,186,1) -50%, rgba(104,64,186,1) 100%)",
      }}
    >
      <Container
        className="d-flex align-items-center"
        style={{ position: "relative", zIndex: 5 }}
      >
        {!!icon && icon}
        <div>
          <h1
            className={`${!props.subtitle && "p-0 mb-0"} ${Title}`}
            style={{ color: "var(--white)" }}
          >
            {props.title}
          </h1>
          {!!props.subtitle && (
            <p
              className={`mb-0 pb-0 ${Subtitle}`}
              style={{ color: "var(--white)" }}
            >
              {props.subtitle}
            </p>
          )}
        </div>
      </Container>
    </Jumbotron>
  )
}

export default Hero
